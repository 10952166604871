<template>
  <UiLoader :loading="loading">
    <Swiper
      class="!overflow-visible"
      :initial-slide="todayIndex"
      :modules="[SwiperMousewheel, SwiperNavigation]"
      :mousewheel="{
        forceToAxis: true,
      }"
      :navigation="{
        nextEl: '.flower-swiper-next',
      }"
      slides-per-view="auto"
      @swiper="initSwiper"
    >
      <SwiperSlide
        v-for="item in flowerList"
        :key="item.uuid"
        class="pr-4 sm:pr-6 lg:pr-8"
      >
        <FlowerCard
          class="w-full"
          :flower-day="item"
          :transition="transition"
        />
      </SwiperSlide>

      <button
        class="
          flower-swiper-next
          absolute left-auto right-0 top-1/2 z-10
          flex h-16 w-16
          -translate-y-1/2
          items-center justify-center
          rounded-full bg-white drop-shadow transition
          hover:bg-primary-400 hover:text-primary disabled:opacity-30 disabled:saturate-0
          max-sm:scale-75 sm:right-5
       "
      >
        <UiIcon name="ArrowRight" />
      </button>
    </Swiper>
  </UiLoader>
</template>

<script setup lang="ts">
import {DateTime} from 'luxon';
import {useFlowersQuery} from '~/graphql/graphql';
import type {Swiper} from 'swiper';

defineProps<{
  transition?: boolean,
}>();

const modelValue = defineModel<DateTime>({
  default: DateTime.now(),
  required: false,
});

const swiper = ref<Swiper>();

const todayIndex = computed(() => Math.max(0, flowerList.value.findIndex(flower => flower.date === DateTime.now().toISODate())));

const {result: flowerListResult, loading, onResult} = useFlowersQuery(() => ({
  dateStart: modelValue.value.startOf('month').toISODate(),
  dateEnd: modelValue.value.endOf('month').toISODate(),
}), {
  keepPreviousResult: true,
});

const flowerList = computed(() => flowerListResult.value?.flowers ?? []);

onResult(() => {
  swiper.value?.slideTo(todayIndex.value);
});

function initSwiper(swiperInstance: Swiper) {
  swiper.value = swiperInstance;
}

</script>

<style scoped>
.swiper-slide {
  width: calc(90%);

  @screen sm {
    width: calc(46% + (theme('padding.6') / 2));
  }

  @screen lg {
    width: calc((94% / 3) + (theme('padding.8') / 3));
  }

  @screen xl {
    width: calc((100% / 3) + (theme('padding.8') / 3));
  }
}
</style>
